  import React, { useRef, useEffect } from 'react';
  import { graphql } from 'gatsby';
  import { StaticImage } from 'gatsby-plugin-image';
  import PropTypes from 'prop-types';
  import { Helmet } from 'react-helmet';
  import styled from 'styled-components';
  import { srConfig } from '@config';
  import sr from '@utils/sr';
  import { Layout } from '@components';
  import { usePrefersReducedMotion } from '@hooks';

  const StyledTableContainer = styled.div`
    margin: 100px -20px;

    @media (max-width: 768px) {
      margin: 50px -10px;
    }

    table {
      font-size: 25px;
      width: 100%;
      border-collapse: collapse;

      .hide-on-mobile {
        @media (max-width: 768px) {
          display: none;
        }
      }

      tbody tr {
        &:hover,
        &:focus {
          background-color: var(--light-navy);
        }
      }

      tbody tr th {
        font-weight: normal;
      }

      th,
      td {
        padding: 10px;
        text-align: left;

        &:first-child {
          padding-left: 20px;

          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }
        &:last-child {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      tr {
        
        cursor: default;

        td:first-child {
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }
        td:last-child {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }

      td {
        &.year {
          padding-right: 20px;

          @media (max-width: 768px) {
            padding-right: 10px;
            font-size: var(--fz-sm);
          }
        }

        &.title {
          padding-top: 15px;
          padding-right: 20px;
          color: var(--lightest-slate);
          font-size: var(--fz-xl);
          font-weight: 600;
          line-height: 1.25;
        }

        &.company {
          font-size: var(--fz-lg);
          white-space: nowrap;
        }

        &.tech {
          font-size: var(--fz-xxs);
          font-family: var(--font-mono);
          line-height: 1.5;
          .separator {
            margin: 0 5px;
          }
          span {
            display: inline-block;
          }
        }

        &.links {
          min-width: 100px;

          div {
            display: flex;
            align-items: center;

            a {
              ${({ theme }) => theme.mixins.flexCenter};
              flex-shrink: 0;
            }

            a + a {
              margin-left: 10px;
            }
          }
        }
      }
    }
  `;

  const StyledAboutSection = styled.section`

  counter-reset: section;

  max-width: 1400px;

  h2 b {
    color: #828CAC;
  }

  h2, h3 {
    color: #828CAC;
  }

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .numbered-heading {
    color: #828CAC;
    font-size: 42px;
    font-weight: bold;

    &:before {
      color: #828CAC;
      counter-increment: section 1;
      content: '0' counter(section) '.';
      font-size: clamp(var(--fz-md), 3vw, 24px);
      font-weight: bold;
    }

    &:after {
      background-color: #828CAC;
      width: 400px;
    }
  }

  
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  width: 700px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }
    }


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid #828CAC;
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

  const ArchivePage = ({ location, data }) => {
    const projects = data.allMarkdownRemark.edges;
    const revealTitle = useRef(null);
    const revealTable = useRef(null);
    const revealProjects = useRef([]);
    const prefersReducedMotion = usePrefersReducedMotion();

    useEffect(() => {
      if (prefersReducedMotion) {
        return;
      }

      sr.reveal(revealTitle.current, srConfig());
      sr.reveal(revealTable.current, srConfig(200, 0));
      revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
    }, []);

    return (
      <Layout location={location}>
        <Helmet title="Archive" />

        <main>
          <header ref={revealTitle}>
            <h1 className="big-heading" style={{color: "#828CAC"}}>Pet Pat</h1>
            <p className="subtitle" style={{fontSize:"22px", color: "#828CAC"}}>A multi-functional pet community platform.</p>
          </header>

          <StyledTableContainer ref={revealTable}>
            <table style={{color: "#828CAC"}}>
              <thead>
                <tr>
                  <th>Project Type</th>
                  <th>Team Members</th>
                  <th className="hide-on-mobile">My Role</th>
                  <th className="hide-on-mobile">Duration</th>
                  <th>Instructor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>INFO 360 <br/> Course Project</th>
                  <th>Yifan Wang , Jeffrey Qiu <br/> Zaozao Li , Alina Wang</th>
                  <th>User Research <br/> UI/UX Design <br/> Team Lead</th>
                  <th>March 2021 - June 2021 <br/> (3 months)</th>
                  <th>Dr. Linda Kotut</th>
                </tr>
              </tbody>
            </table>
          </StyledTableContainer>

          <StyledAboutSection id="about" ref={revealTable}>
        <h2 className="numbered-heading">Overview</h2>
        
        <div className="inner">
          <StyledText>
            <div>
              <h2 style={{fontSize: "33px"}}><b>About Pet Pat</b></h2>

              <h3>
              The ongoing epidemic has caused people to spend more time at home and has given many people 
              the idea of raising a pet. 
              <br/><br/>
              According to a Google Trends research, “compared to the same period in 2019, 
              the RSV ratio (2020/2019) for both dog and cat adoption increased by up to 250%” (Ho, 2021). 
              </h3>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>Challenges</b></h2>

              <h3>
              Pet owners are experiencing difficulties on finding pet-related information in a consolidate platform that includes the social media, product suggestion.
              </h3>

              <br/>

              <h2 style={{fontSize: "33px"}}><b>Goals</b></h2>

              <h3>
              Design a pet community app where pet owners can share and find pet-related information in a consolidate platform. 
              </h3>

              <br/>

            </div>

          </StyledText>

          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/petpat/cover.png"
                width={700}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/>

        {/* Section 02 - Initial Research */}

        <h2 className="numbered-heading" >Overview of Design Process</h2>

        <div style={{justifyContent: "center", textAlign: "left", display: "flex"}} className="inner">

        <StyledText style={{width: "300px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>01. Research</b></h2>

              <h2> Competitor Analysis <br/>
                   Survey Result & Insights<br/>
                   Define the Solution 
              </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "300px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>02. Ideation</b></h2>

              <h2> Features <br/>
                   Low-Fi Design <br/>
                   User Interview & Test <br/>
                   Low-Fi Refinement
              </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "300px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>03. Design</b></h2>

              <h2> High-Fidelity Design <br/>
                   User Evaluation
              </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "300px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>04. Reflection</b></h2>

              <h2> Challenge & Takeaways <br/>
                   Limitations
              </h2>

            </div>
          </StyledText>

        </div>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - Research */}

        <h2 className="numbered-heading" >Research</h2>

        <div className="inner">
          <StyledText>
            <div>

            <br/><br/><br/><br/><br/><br/><br/><br/>

              <h2 style={{fontSize: "33px"}}><b>Competitor Analysis</b></h2>

              <br/>

              <h2>
              We conducted 4 famous pet-related applications in the market: <br/><br/>
              <b>Chewy</b> - online supplies shop <br/><br/>
              <b>Puppr</b> - pet training <br/><br/>
              <b>Tiktok</b> - social platform <br/><br/>
              <b>Pet First Aid</b> - pet healthcare
              </h2>

            </div>

          </StyledText>

          <StyledPic style={{width: "800px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/petpat/table.png"
                width={800}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>
        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "40px", textAlign: "center"}}><b>Survey Result & Insights</b></h2>

        <br/>

        <h2 style={{textAlign: "center"}}>We sent out surveys to find out the concerns pet owners have when they are looking for pet-related information. </h2>

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

          <StyledPic style={{width: "1300px"}}>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="../images/petpat/survey.png"
                width={1300}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </StyledPic>

        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "40px", textAlign: "center"}}><b>Define the Solution</b></h2>

        <br/>

        <h2 style={{textAlign: "center"}}>We propose the solution of a mobile app called Pet Pat 
        that integrates <br/> useful functionalities and fully addresses the concerns of our target user pet owners. </h2>

        <br/><br/><br/><br/><br/>

        {/* Section 03 - Ideate */}

        <h2 className="numbered-heading" >Ideation</h2>

        <h2 style={{fontSize: "38px", textAlign: "center"}}><b>Finalized Features</b></h2>

        <br/>

        <h2 style={{textAlign: "center"}}> 
          After the research and brainstorming, we choose three key features for Pet Pat and each dedicated to addressing a specific area of problems from the survey. 
        </h2>

        <br/>

        <div style={{justifyContent: "center", textAlign: "center", display: "flex"}} className="inner">

        <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Community Feeds</b></h2>

              <br/>
              
              <h2> Users can post feedback of any pet products, dining experience, and pet-friendly locations. </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Pet products</b></h2>

              <br/>

              <h2>View the top choices of pet products rated and posted about by our platform’s prospective users. </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "350px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Pet-friendly Locations</b></h2>

              <br/>

              <h2>Look up pet-friendly locations such as restaurants and hotels  by entering the zip code or travel destinations. </h2>

            </div>
          </StyledText>

        </div>

        <br/><br/><br/>

        <div className="inner">

            <StyledText>

            <br/><br/><br/><br/>

              <div>              
                <h2 style={{fontSize: "33px"}}><b>Low-fidelity</b>
                </h2>
                <br/>
                <h2> We created 3 Low-fidelity framework based on the features selected, and interviewed two of our respondents to collect feedback before designing the high-fidelity work.  </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "700px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/lowfi.png"
                  width={700}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/>

                <div style={{textAlign: "center"}} className="inner">

        <StyledText>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>User Interview Questions</b></h2>

              <br/>
              
              <h2 style={{textAlign: "left"}}>⦿ Which prototype would you think best suits our purpose and is the most convenient to use? </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ What is the best part of our prototype? </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ What are some aspects that we need to work on? </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Do you have any questions about our prototype? </h2>

            </div>
          </StyledText>

          <StyledText style={{width: "600px"}}>
            <div>

            <br/>

              <h2 style={{fontSize: "33px"}}><b>Feedback Insights</b></h2>

              <br/>

              <h2 style={{textAlign: "left"}}>⦿ Both interviewee choose the first Low-Fi Design. They loved the lay out. </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Add text descriptions under each icon in the menu bar for better interpretation. </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Let users to select dog/cat/others as default in the home page </h2>
              <br/>
              <h2 style={{textAlign: "left"}}>⦿ Keep only one search bar on the “finding pet-friendly location” page </h2>

            </div>
          </StyledText>

        </div>

        <br/><br/><br/>

        <div className="inner">

            <StyledText>

            <br/><br/>

              <div>              
                <h2 style={{fontSize: "33px"}}><b>Refinement</b>
                </h2>
                <br/>
                
                <h2> ‣ Tabs: allowing users to choose their pet type as default in home page </h2>
                <br/>
                <h2> ‣ Search Bar: keeping only one search bar for locations </h2>
                <br/>
                <h2> ‣ Menu Bar: adding a menu bar at the bottom on each page with text description </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "700px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/refinement.png"
                  width={700}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/><br/>

        {/* Section 05 - Design */}

        <h2 className="numbered-heading" >Design</h2>

        <h2 style={{fontSize: "40px", textAlign: "center"}}><b>Final Deliverable - The Hi-Fi Prototype </b></h2>

        <br/><br/>

        {/* Feature 1 */}

        <div className="inner">

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/f1.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>1. Community Feeds</b></h2>
                <br/>
                <h2>  ‍‣ Main menu: show posts from other users about their feedback of any pet-related information. <br/><br/> 
                ‍‣ Creating Post Page: user can create their own posts by adding pictures, titles, context, tags, or locations to share information to the community. </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 2 */}

        <div className="inner">

            <StyledText>
              <br/><br/><br/>

              <div>
              
                <h2 style={{fontSize: "33px"}}><b>2. Pet Products</b>
                </h2>
                <br/>
                <h2> ‣ Pet Products Page: view pet products by category and the trending products system recommended. </h2>
                <br/>
                <h2> ‣ Category Page: Show lists of products in the category </h2>

              </div>
            </StyledText>

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/f2.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

        </div>

        <br/><br/><br/>

        {/* Feature 3 */}

        <div className="inner">

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/f3.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>3. Find Pet-friendly Locations</b></h2>
                <br/>
                <h2>  ‍‣ Find Pet-friendly locations page: users can enter the address and use filter to find locations. 
                   <br/><br/> ‍
                   ‣ View Detailed information page: view other’s feed back and write own reviews. </h2>
              </div>
            </StyledText>

        </div>

        <br/><br/><br/>

        {/* Feature 4 */}

        <div className="inner">

            <StyledText>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>4. Account and Profile</b></h2>
                <br/>
                <h2>  ‍‣ Create Account Page: create an account before posting
                   <br/><br/>
                    ‍‣ Profile Setting Page: Let users edit their profile, default location, notification, privacy settings </h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/f4.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/>

        {/* Feature 5 */}

        <div className="inner">

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/eva.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>

            <StyledText>
              
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "33px"}}><b>User Evaluation</b></h2>
                <br/>
                <h2>  ‍During the Final Hi-Fi Design Stage, we selected three users to test our design. We asked them to walk us through our design and answer about their favorite and least favorite part of our design. </h2>
              </div>
            </StyledText>
            
        </div>
        
        <br/><br/><br/>

        {/* Feature 6 */}

        <div className="inner">

            <StyledText>
              <div>
                <br/><br/><br/>
                <h2 style={{fontSize: "40px"}}><b>Prototype Interaction</b></h2>
              </div>
            </StyledText>

            <StyledPic style={{width: "800px"}}>
              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../images/petpat/interaction.png"
                  width={800}
                  quality={100}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </StyledPic>
            
        </div>

        <br/><br/><br/><br/>

        <h2 style={{fontSize: "42px", textAlign: "center"}}><b>Video Presentation</b></h2> 

        <br/>

        <div className="inner" style={{justifyContent: "center", display: "flex"}}>

          <StyledPic style={{width: "1200px"}}>
            <iframe width = "1200px" height = "700px" frameborder="0"
              src="https://www.youtube.com/embed/EVTkqeYMGNs"
              >
            </iframe>
          </StyledPic>

        </div>       
        
        <br/><br/><br/><br/><br/>

        <h2 className="numbered-heading" >Reflection</h2>

        <div>

        <h2 style={{fontSize: "38px"}}><b>Challenges & Key Takeaways </b></h2> 

        <br/>

        <h2 style={{fontSize: "31px"}}><b>First Time Working Through the Entire Design Process</b></h2>       
        <h2>INFO 360 is my first design experience and I really love this challenging class! 
          I faced many challenges idealized the design idea and using the Figma. 
          Under the help of Professor Kotut and discussion with my teammates. 
          We delivered an interesting Pet Pat product. 
          This project experience attracts me to the user-centered design and I decided to pursue a double degree in Information with HCI focused. </h2>
        
        <br/>

        <h2 style={{fontSize: "31px"}}><b>Reflection on Team Collaboration</b></h2>
        <h2>Our team members had different design thinking during the ideation stage, 
          and we had many discussion about which design we should picked. 
          Although our design ideas were diverge in a broad ways, 
          we finally coverage our design idea together through meetings and low-fidelity user interviews. </h2>
        
        <br/><br/>

        <h2 style={{fontSize: "38px"}}><b>Limitations </b></h2> 

        <br/>
        
        <h2 style={{fontSize: "31px"}}><b>Limitation on Pet Category</b></h2>
        <h2>We all think the top issue facing our design is that it may not be that friendly to pet owners who do not have cats and dogs. We set three choices “Cats, Dogs, and Others” for users to choose on all pages for convenience, but the “Others” section may not contain as much information as “Dogs'' and “Cats” do for pets that fall in this category. Because information under the “Others” selection is combined and mixed up, users such as pet fish owners, pet rabbit owners, and other multicultural pet owners may not be able to find straightforward information that is directly related to their own pets. Even though our initial thought is to design an app that is user-friendly to all pet owners, it's inevitable to reduce multicultural pet owners’ liking if we still choose to categorize the selection into only three buttons on the top of the pages.</h2>

        <br/>
        
        <h2 style={{fontSize: "31px"}}><b>Limitation on Growth and Information Update</b></h2>
        <h2>As our app is just at the startup stage, it needs a prolonged time to accumulate users and businesses. To promote our app to the public, a huge budget would be spent on advertising to ensure future registration growth. Also, we need time to collect reviews and comments of various pet food brands and different locations of pet-friendly hotels and provide them for our users to ensure the app is rich in content. Furthermore, to maintain a certain number of active users browsing our app daily, subsequent information updates are also required to ensure our contents are always the most comprehensive and precise.</h2>

        </div>
        
      </StyledAboutSection>
        </main>
      </Layout>
    );
  };
  ArchivePage.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  export default ArchivePage;

  export const pageQuery = graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              tech
              github
              external
              company
            }
            html
          }
        }
      }
    }
  `;
